.header {
  width: 100%;
  height: 65px;
  background: #231f20;
  border-top: 4px solid #3999c9;
  position: fixed;
  top: 0;
  z-index: 1000;
}
.header img {
  margin-left: 15px;
  max-width: 190px;
  margin-top: 13px;
}
