/* contacts-panel.styles.scss */

.contacts-container {
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgb(151, 145, 145);
  height: 100vh;
  width: 300px;
}

.main-contacts-container {
  flex: 1;
  overflow-y: auto;
}

.search-contacts {
  padding: 16px;
  border-right: 1px solid rgb(151, 145, 145);
  border-bottom: 1px solid rgb(151, 145, 145);
  // background-color: #36454f;
  z-index: 10;
  width: 100%;

  .search-text-field {
    .MuiInputBase-input {
      // color: white;
      color: black;
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid black;
    }

    .MuiInput-underline:after {
      border-bottom: 2px solid black;
    }

    .MuiInputLabel-root {
      color: black;
    }
  }
}
