.user-profile-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  //   margin-left: 10px;
  margin-bottom: 10px;
}

// sx={{
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "flex-start",
//     justifyContent: "flex-start",
//     // width: "100%",
//     marginLeft: 4,
//     marginBottom: 4,
//   }}
