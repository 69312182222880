.app {
  display: flex;
  flex-direction: column;
  // min-height: 100vh;
  max-height: 100vh;
  .app-container {
    display: flex;
    flex: 1;
    // margin-top: 64px;
  }
}

::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the thumb */
  border-radius: 6px; /* Roundness of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb when hovered */
}
