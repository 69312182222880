.footer {
  width: 100%;
  height: 40px;
  background: #231f20;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.footer p {
  color: #fff;
  padding-left: 35px;
  font-size: 12px;
  margin-top: 10px;
}
