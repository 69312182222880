.feature-codes-panel {
  margin: 20px;
  padding: 20px;
  // background-color: #f5f5f5;
  box-shadow: none;
  border: none;

  .MuiTableCell-root {
    padding: 10px;
  }

  .section-title {
    font-weight: bold;
    background-color: #e0e0e0;
    padding: 10px;
  }

  .feature-codes-section {
    margin-bottom: 20px;
  }
}
