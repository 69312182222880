.on-call-display-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  align-items: center;
  position: relative;
  padding: 16px; /* Adjust the padding as needed */
}

.label-icon-container {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 6px;
}

.popover {
  position: relative;
}

.popover::before {
  content: "";
  position: absolute;
  top: -10px; /* Adjust based on the arrow size */
  right: 10px; /* Align the arrow with the icon */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white; /* Match the Popover background */
  z-index: 1;
}

.button-label {
  display: flex;
  color: black;
  justify-content: flex-start;
  text-transform: none; /* Prevent text from being uppercased */
  width: auto;
  min-width: 0; /* Ensure button width fits content */
}
