.call-control-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transform: scale(1.4);
  padding: 20px;
  gap: 1rem;

  .call-control-button {
    padding: 5px;
    .call-control-icon {
      font-size: 0.8em;
    }
  }
}
