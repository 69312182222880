.login {
  .MuiTextField-root {
    margin: 8px; // '1' in Material-UI's spacing typically equals '8px'
    width: 55ch;
  }
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  resize: vertical;
  text-align: center;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;

  .login-box {
    width: 25%;
    background: #fff;
    resize: vertical;
    margin: auto;
    padding: 25px 0;
    position: relative;

    .textInput {
      float: left;
      margin-left: 0px;
    }

    .textInput:focus {
      border-bottom: 2px solid #3297bb;
      font-style: normal;
      outline: none;
    }

    .submitButton {
      width: 40%;
      float: left;
      border: none;
      padding: 8px;
      border-radius: 2px;
      margin-top: 10px;
      font-style: normal;
      cursor: pointer;
      background: #3999c9;
      color: #fff;
      transition: 0.2s;
      -webkit-transition: 0.2s;
      -moz-transition: 0.2s;
      -ms-transition: 0.2s;
      -o-transition: 0.2s;
    }

    .submitButton:disabled {
      background: #eee;
      color: #c3c2c2;
    }

    a {
      position: absolute;
      bottom: -30px;
      left: 5px;
      color: #555555;
      font-style: italic;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
