.user-profile-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
//sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}

.avatar-box {
  position: relative;
  margin-right: 1rem;
}
//sx={{ position: "relative", marginRight: "1rem" }}

.avatar {
  cursor: pointer;
}
//sx={{ cursor: "pointer" }}

.status-dot {
  position: absolute;
  right: -5px;
  bottom: -4px;
  width: 16px;
  height: 16px;
  //   background-color: #56f000; // Replace with dynamic color
  border-radius: 50%;
  border: 2px solid white;
}
//sx={{position: "absolute", right: -5, bottom: -4, width: 16, height: 16, backgroundColor: statusColors[status], borderRadius: "50%", border: "2px solid white",}}

.username {
  color: black;
}
//sx={{ color: "black" }}

// ... [Other styling specific to the component]
