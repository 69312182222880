.backdrop {
  color: #fff;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; // Aligns the notification container to the top
  padding-top: 26px; // Adds a gap at the top
}

.notificationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  padding: 0.9rem;
  width: 80%;
  max-width: 400px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.callerInfo {
  align-self: stretch;
  margin-bottom: 0.5rem;
  color: black;
  margin-bottom: 0px;
}

.buttonsContainer {
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  // margin-top: auto; // Push buttons to the bottom
  gap: 14px;
}

.buttonsContainer button {
  border-radius: 25px; // Adjust this value for desired roundness
  min-width: 52px; // Adjust this value for desired width
  height: 35px; // Set a fixed height to ensure pill shape
  // color: #000000;
}

.buttonsContainer button:first-child {
  background-color: red;
}

.buttonsContainer button:last-child {
  background-color: green;
}
