.caller-id-control {
  text-align: left;
  position: relative;
  margin: 24px;

  .controls {
    display: flex;
    align-items: center;

    .form-control {
      min-width: 100%;

      .caller-id-number-label {
        background-color: white;
        padding-left: 6px;
        padding-right: 6px;
      }
    }
  }
}
