.voicemail-list {
  margin: 20px;
  padding: 20px;
  border: none;
  box-shadow: none;

  .MuiTableCell-root {
    padding: 10px;
  }

  .MuiIconButton-root {
    margin-right: 8px;
  }
}
