.dialpad-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  align-items: center;
  justify-content: center;

  .keypad-button {
    color: black;
    border-radius: 50%;
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: white;
  }
}
