/* call-history-panel.styles.scss */

.main-content {
  flex-grow: 1;
  padding: 16px;
  transition: margin 0.3s, width 0.3s;
  flex: 1 1 auto;
  margin-right: 272px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.search-container {
  padding-bottom: 16px;
  position: absolute;
  width: 100%;
  background-color: white; /* Adjust background color as needed */
  // z-index: 10;
  left: 0; /* Ensure it's aligned to the left edge */
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

.call-history-container {
  flex: 1;
  overflow-y: auto;
  margin-top: 64px; /* Adjust this value to match the height of the search bar */
}

.call-history-list {
  padding-top: 16px;
}
