// src/components/loading/loading.styles.scss
.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; // Ensure it is on top of other content
  width: 100%;
  height: 100%;
}
